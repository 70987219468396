<template>
  <b-modal
    id="copy-ratings-modal"
    ref="copy-ratings-modal"
    :title="$t('broadcastSideBar.copyRating')"
    :ok-disabled="$v.$invalid || isBusy"
    :ok-title="$t('broadcast.copy')"
    :cancel-title="$t('table.cancel')"
    size="sm"
    @ok.prevent="copyRatings"
    @hidden="clearFields"
  >
    <form @submit.stop.prevent="checkIfValidThenEnter">
      <b-form-group :label="$t('channelModal.from')" label-for="datepicker-copy-rating-from">
        <datepicker-wrapper
          id="datepicker-copy-rating-from"
          v-model="from"
          required
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :disabled="isBusy"
        />
      </b-form-group>

      <b-form-group :label="$t('channelModal.on')" label-for="datepicker-copy-rating-to-calendar" class="mx-auto">
        <b-calendar
          id="datepicker-copy-rating-to-calendar"
          v-model="calendarValue"
          :date-info-fn="dateClass"
          :date-disabled-fn="calendarDateDisabled"
          :locale="isLocale"
          hide-header
          :label-today="$t('datepicker.today')"
          :label-no-date-selected="$t('datepicker.noDateSelected')"
          :label-close-button="$t('table.close')"
          :label-help="$t('datepicker.help')"
          :label-current-month="$t('datepicker.currentMonth')"
          :label-next-month="$t('datepicker.nextMonth')"
          :label-next-year="$t('datepicker.nextYear')"
          :label-prev-month="$t('datepicker.prevMonth')"
          :label-prev-year="$t('datepicker.prevYear')"
          :label-today-button="$t('datepicker.todayButton')"
          :start-weekday="startWeekdayIndex"
          :initial-date="from"
          :disabled="isBusy || !from"
          @selected="selectedCalendarEvent"
        />
        <div class="mt-2">{{ $t('multiselect.selected') }}: <br />{{ showSelectedDates }}</div>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import { BCalendar } from 'bootstrap-vue';
import createDateFromString from '@/utils/createDateFromString';

export default {
  name: 'CopyRating',
  components: { DatepickerWrapper, BCalendar },
  props: {
    channelId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      startWeekdayIndex: process.env.VUE_APP_START_WEEK_DAY ? +process.env.VUE_APP_START_WEEK_DAY : 1,

      from: '',
      calendarValue: '',
      selectedDateRange: [],

      isBusy: false,
    };
  },
  validations: {
    from: { required },
    selectedDateRange: { required },
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),

    showSelectedDates() {
      if (this.selectedDateRange.length === 0) return '-';
      else return this.selectedDateRange.map((d) => createDateFromString(d).toLocaleDateString()).join(', ');
    },
  },
  watch: {
    from(value) {
      if (this.selectedDateRange.includes(value)) this.selectedDateRange = this.selectedDateRange.filter((d) => d !== value);
    },
  },
  methods: {
    clearFields() {
      this.from = '';
      this.calendarValue = '';
      this.selectedDateRange = [];
    },

    hideModalCopyRatings() {
      this.$bvModal.hide('copy-ratings-modal');
    },

    dateClass(ymd, date) {
      let classStr = '';
      if (this.selectedDateRange.includes(ymd)) classStr = 'table-danger';
      return classStr;
    },

    selectedCalendarEvent(ymd, date) {
      if (this.selectedDateRange.includes(ymd)) this.selectedDateRange = this.selectedDateRange.filter((e) => e !== ymd);
      else {
        this.selectedDateRange.push(ymd);
        this.selectedDateRange.sort();
      }
      this.calendarValue = '';
    },

    calendarDateDisabled(ymd, date) {
      // Return `true` if the date should be disabled
      return this.from === ymd;
    },

    async copyRatings() {
      const formData = {
        channel_id: this.channelId,
        source_date: this.from,
        target_dates: this.selectedDateRange,
      };
      if (this.selectedDateRange.some((d) => new Date(this.from) > new Date(d))) {
        const modalResult = await this.$bvModal
          .msgBoxConfirm(`${this.$i18n.t('table.areYouSureToCopyRatingsOnPrevDate')}`, {
            title: this.$i18n.t('table.copyToPreviousDate'),
            size: 'sm',
            okTitle: this.$i18n.t('table.yes'),
            cancelTitle: this.$i18n.t('table.no'),
            autoFocusButton: 'ok',
            id: 'confirm-copy-prev-day-grp-modal',
          })
          .catch((err) => {});
        if (!modalResult) return;
        await this.postCopyDayGrp(formData);
      } else {
        await this.postCopyDayGrp(formData);
      }
    },

    async postCopyDayGrp(formData) {
      this.isBusy = true;
      await this.$store.dispatch('POST_COPY_DAY_GRP', {
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.ratingsCopied'),
          });
          this.hideModalCopyRatings();
          this.$emit('updateData');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },

    checkIfValidThenEnter() {
      if (!this.$v.$invalid) this.copyRatings();
    },
  },
};
</script>
